//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Filters from './TableFilters.vue';
import Modal from './Modal.vue';

import tableConfig from './tableConfig';
import actionsConfig from './actionsConfig';

export default {
  components: { Filters, Modal },

  props: {
    merchantId: { type: Number, default: null },
  },

  data() {
    return {
      editFee: {
        show: false,
        data: null,
        isCreating: false,
      },
      tableConfig,
      actionsConfig,
      selection: [],
      loader: false,
    };
  },

  // SINGLE TABLE COMPONENT
  // async mounted() {
  //   const { page } = this.$route.query;
  //   const numberPage = Number(page);
  //   if (numberPage) {
  //     this.setPageAction(numberPage);
  //   }

  //   this.setGeneralLoader(true);
  //   await this.loadData();
  //   this.setGeneralLoader(false);
  // },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('merchantsFees', ['page', 'pages', 'data', 'sort']),

    serviceMerchantId() {
      const { merchantId } = this;
      if (merchantId) return [merchantId];
      return undefined;
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('merchantsFees', {
      loadDataAction: 'loadData',
      loadIndividualDataAction: 'loadIndividualData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      editFeeAction: 'editFee',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      if (this.serviceMerchantId) {
        await this.loadIndividualDataAction({ ...data, serviceMerchantId: this.serviceMerchantId });
      } else {
        await this.loadDataAction(data);
      }
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      // SINGLE TABLE COMPONENT
      // const query = { page: value };
      // this.$router.replace({ query });

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    edit(row) {
      this.editFee.isCreating = false;
      this.editFee.show = true;
      this.editFee.data = row;
    },

    create() {
      this.editFee.isCreating = true;
      this.editFee.show = true;
      this.editFee.data = null;
    },

    applyEdit({ input }) {
      this.confirmAction({
        text: 'Are you sure?',
        callback: async () => {
          this.setGeneralProgress(true);
          try {
            await this.editFeeAction({ ...input, serviceMerchantId: this.merchantId });
            if (this.serviceMerchantId) {
              await this.loadIndividualDataAction({ serviceMerchantId: this.serviceMerchantId });
            } else {
              await this.loadDataAction();
            }
            this.editFee.show = false;
            this.setSuccessNotification('Fee edited');
          } catch (error) {
            this.setErrorNotification(error.error.message);
          }
          this.setGeneralProgress(false);
        },
      });
    },
  },
};
