export default [
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Currency',
    },
    cell: {
      type: 'text',
      value: (e) => e.currency,
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Method',
    },
    cell: {
      type: 'text',
      value: (e) => e.method,
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Provider',
    },
    cell: {
      type: 'text',
      value: (e) => e.provider,
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Type',
    },
    cell: {
      type: 'slot',
      name: 'type',
    },
  },
  {
    width: 20,
    header: {
      type: 'text',
      caption: 'Fee',
    },
    cell: {
      type: 'slot',
      name: 'fee',
    },
  },
  {
    width: 32,
    header: {
      type: 'text',
      caption: 'Fee limits',
    },
    cell: {
      type: 'slot',
      name: 'feeLimits',
    },
  },
];
